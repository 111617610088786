import { Accordion, Box, Center, Container, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { jobListings } from "./data";
import { FeatureItem } from "./FeatureItem";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";

export const About = () => {
  const MotionBox = motion(Box);

  const controls1 = useAnimation();
  const controls2 = useAnimation();

  return (
    <Box id="about" w="100vw" height={{ base: "1500px", md: "70vh" }}>
      <SimpleGrid h="100%" columns={{ base: 1, md: 2 }}>
        <InView
          as="div"
          triggerOnce={false} // This will trigger the animation every time
          threshold={0.4}
          onChange={(inView, entry) => {
            if (inView) {
              controls1.start({ y: 0, opacity: 1 });
            } else {
              controls1.start({ y: -200, opacity: 0 });
            }
          }}
        >
          <Center
            h="100%"
            pl={14}
            py={100}
            flexDir="column"
            alignItems="flex-start"
            bg="brand.100"
            sx={{
              clipPath: { lg: "polygon(0% 0%, 100% 0%, 93% 100%, 0% 100%)" },
            }}
          >
            <MotionBox animate={controls1} initial={{ y: -200, opacity: 0 }} transition={{ duration: 1 }}>
              <Heading pb={4} as="h1" size={{ base: "md", md: "lg" }} color="whitesmoke">
                Why choose use?
              </Heading>

              <Text textStyle={{ base: "lg", md: "xl" }} color="whitesmoke" w="60%">
                Choose agility and affordability with CODEHARDY. As a smaller team, we deliver rapid, high-quality tech solutions without the big-player price tag. Quick turnarounds, personalized service, and cost-effective rates – that's the CODEHARDY advantage.
              </Text>
            </MotionBox>
          </Center>
        </InView>
        <InView
          as="div"
          triggerOnce={false} // This will trigger the animation every time
          threshold={0.4}
          onChange={(inView, entry) => {
            if (inView) {
              controls2.start({ x: 0, opacity: 1 });
            } else {
              controls2.start({ x: 200, opacity: 0 });
            }
          }}
        >
          <Center h="100%" pl={14} py={100} flexDir="column" alignItems="center">
            <MotionBox animate={controls2} initial={{ x: 200, opacity: 0 }} transition={{ duration: 1 }} w="100%">
              <Heading pb={4} as="h1" size={{ base: "md", md: "lg" }} color="brand.100">
                Our Values and Mission
              </Heading>

              <Text color="#333333" w="80%" fontWeight={300}>
                At CODEHARDY, our mission is rooted in a simple, yet powerful realization from our extensive experience with big players in the tech industry: quality and affordability should go hand in hand. Having witnessed firsthand the trend of overcharging and underdelivering, we've set out to chart a different course. <br />
                <br />
                We believe in fair pricing, ensuring that high-quality tech solutions are accessible without exorbitant costs. Our values are built on the pillars of transparency, integrity, and excellence. We commit to delivering only the best in website and app development, database management, and cloud solutions, with a pricing model that respects our clients' budgets and trust. <br /> <br /> In a world where technology is crucial yet often overpriced, CODEHARDY stands as a beacon of honesty
                and quality. We're not just building solutions; we're nurturing long-term partnerships based on mutual respect and shared success.
              </Text>
            </MotionBox>
          </Center>
        </InView>
      </SimpleGrid>
    </Box>
  );
};
