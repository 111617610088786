import React, { useState } from "react";
import { Box, Button, Center, FormControl, FormLabel, Heading, Input, SimpleGrid, Text, Textarea, VStack } from "@chakra-ui/react";

export const Contact: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    // Create the email body
    const body = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;

    // Create the mailto URL
    const mailtoUrl = `mailto:marcos.hardy@outlook.com?subject=Contact Form Submission&body=${encodeURIComponent(body)}`;

    // Open the mail client with the mailto URL
    window.location.href = mailtoUrl;
  };

  return (
    <Box id="contact-form" w="100vw" height={{ base: "1100px", md: "70vh" }}>
      <SimpleGrid h="100%" columns={{ base: 1, md: 2 }}>
        <Center
          pl={14}
          py={100}
          flexDir="column"
          alignItems="flex-start"
          bg="brand.100"
          sx={{
            clipPath: { lg: "polygon(0% 0%, 86% 0%, 79% 100%, 0% 100%)" },
          }}
        >
          <Heading pb={4} as="h1" size={{ base: "md", md: "lg" }} color="whitesmoke">
            Get in contact.
          </Heading>

          <Text textStyle={{ base: "lg", md: "xl" }} color="whitesmoke" w="60%">
            Ready to elevate your digital presence? Connect with CODEHARDY today, and let's turn your tech aspirations into reality!
          </Text>
        </Center>
        <Box alignSelf="center" justifySelf="center" w="80%">
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="start">
              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter your name" />
              </FormControl>

              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter your email" />
              </FormControl>

              <FormControl id="message" isRequired>
                <FormLabel>Message</FormLabel>
                <Textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Enter your message" />
              </FormControl>

              <Button type="submit" bg="brand.100" color="white">
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
