import { About, Contact, Features, Hero } from "../components";

export const LandingPage = () => (
  <>
    <Hero />
    <About />
    <Features />
    <Contact />
  </>
);
