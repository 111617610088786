import { Accordion, Box, Center, Container, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { jobListings } from "./data";
import { FeatureItem } from "./FeatureItem";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";

export const Features = () => {
  const MotionBox = motion(Box);

  const controls = useAnimation();

  return (
    <Box id="services" w="100vw" height={{ base: "1100px", md: "70vh" }}>
      <SimpleGrid h="100%" columns={{ base: 1, md: 2 }}>
        <InView
          as="div"
          triggerOnce={false} // This will trigger the animation every time
          threshold={0.4}
          onChange={(inView, entry) => {
            if (inView) {
              controls.start({ y: 0, opacity: 1 });
            } else {
              controls.start({ y: 200, opacity: 0 });
            }
          }}
        >
          <Center
            h="100%"
            pl={14}
            py={100}
            flexDir="column"
            alignItems="flex-start"
            bg="brand.100"
            sx={{
              clipPath: { lg: "polygon(0% 0%, 93% 0%, 86% 100%, 0% 100%)" },
            }}
          >
            <MotionBox animate={controls} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
              <Heading pb={4} as="h1" size={{ base: "md", md: "lg" }} color="whitesmoke">
                Our services
              </Heading>

              <Text textStyle={{ base: "lg", md: "xl" }} color="whitesmoke" w="60%">
                At CODEHARDY, we believe in turning your digital dreams into reality. Our suite of services is designed to cater to all your technical needs, ensuring your online presence is robust, sleek, and cutting-edge. Here's what we can do for you:
              </Text>
            </MotionBox>
          </Center>
        </InView>
        <Accordion my={10} p={4} defaultIndex={0}>
          {jobListings.map((listing, id) => (
            <FeatureItem key={id} {...listing} />
          ))}
        </Accordion>
      </SimpleGrid>
    </Box>
  );
};
