import { Button, Drawer, DrawerBody, DrawerContent, DrawerProps, Stack } from "@chakra-ui/react";

export const MobileDrawer = (props: Omit<DrawerProps, "children">) => (
  <Drawer placement="top" {...props} blockScrollOnMount>
    <DrawerContent>
      <DrawerBody mt="16">
        <Stack spacing="6" align="stretch">
          {["Why Us?", "Services"].map((item) => (
            <Button
              key={item}
              size="lg"
              variant="text"
              colorScheme="gray"
              onClick={() => {
                props.onClose();
                if (item === "Why Us?") {
                  // scroll to the id contact-form
                  const element = document.getElementById("about");
                  element?.scrollIntoView({ behavior: "smooth" });
                }
                if (item === "Services") {
                  // scroll to the id contact-form
                  const element = document.getElementById("services");
                  element?.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              {item}
            </Button>
          ))}
          <Button
            onClick={() => {
              props.onClose();

              const element = document.getElementById("contact-form");
              element?.scrollIntoView({ behavior: "smooth" });
            }}
            bg="brand.100"
            color="white"
          >
            Contact
          </Button>
        </Stack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);
