import { Box, Button, ButtonGroup, HStack, Image, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { MobileDrawer } from "./MobileDrawer";
import { ToggleButton } from "./ToggleButton";

const Logo = require("../../assets/codehardy-logo.png");

export const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();
  return (
    <Box as="section">
      <Box borderBottomWidth="1px" bg="bg.surface" position="relative">
        <Box py="3" px="7" w="100vw">
          <HStack justify="space-between">
            <Image height="auto" w="120px" src={Logo} />
            {isDesktop ? (
              <HStack spacing="8">
                <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing="8">
                  {["Why Us?", "Services"].map((item) => (
                    <Button
                      key={item}
                      onClick={() => {
                        if (item === "Why Us?") {
                          // scroll to the id contact-form
                          const element = document.getElementById("about");
                          element?.scrollIntoView({ behavior: "smooth" });
                        }
                        if (item === "Services") {
                          // scroll to the id contact-form
                          const element = document.getElementById("services");
                          element?.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </ButtonGroup>
                <Button
                  onClick={() => {
                    const element = document.getElementById("contact-form");
                    element?.scrollIntoView({ behavior: "smooth" });
                  }}
                  bg="brand.100"
                  color="white"
                >
                  Contact
                </Button>
              </HStack>
            ) : (
              <>
                <ToggleButton onClick={mobileNavbar.onToggle} isOpen={mobileNavbar.isOpen} aria-label="Open Menu" zIndex="tooltip" />
                <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
              </>
            )}
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
