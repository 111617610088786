import { AccordionButton, AccordionItem, AccordionPanel, Badge, Button, HStack, Stack, Text } from "@chakra-ui/react";
import { JobListing } from "./data";

export const FeatureItem = (props: JobListing) => {
  const { title, department, description } = props;
  return (
    <AccordionItem py="4">
      <AccordionButton gap={4} px="0">
        <Text as="h2" fontWeight="semibold" textStyle="xl">
          {title}
        </Text>
        <Badge variant="pill">{department}</Badge>
      </AccordionButton>
      <AccordionPanel px="0">
        <Stack spacing={{ base: "6", md: "8" }}>
          <Stack spacing={{ base: "4", md: "5" }}>
            <Text color="fg.muted">{description}</Text>
            <HStack spacing={{ base: "5", md: "6" }}>
              <Text color="GrayText">Get in contact for more information on rates.</Text>
            </HStack>
          </Stack>
          <Button
            onClick={() => {
              // scroll to the id contact-form
              const element = document.getElementById("contact-form");
              element?.scrollIntoView({ behavior: "smooth" });
            }}
            alignSelf="start"
            bg="brand.100"
            color="white"
          >
            Enquire
          </Button>
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};
