import * as React from "react";
import { createBrowserRouter } from "react-router-dom";

import { Error, FeaturesPage, LandingPage, Layout } from "./routes";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/features",
        element: <FeaturesPage />,
      },
    ],
  },
]);
