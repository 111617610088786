import { Badge, Box, Button, Container, Heading, Img, Stack, Text } from "@chakra-ui/react";
import { InView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

export const Hero = () => {
  const MotionStack = motion(Stack);

  const controls = useAnimation();

  return (
    <Box position="relative" height={{ base: "1000px", lg: "80vh" }}>
      <Container p={{ base: "16", md: "24" }} height="full" minW="100vw">
        <Stack direction={{ base: "column", lg: "row" }} spacing={{ base: "16" }} align={{ lg: "center" }} height="full">
          <InView
            as="div"
            threshold={0.4}
            triggerOnce={false} // This will trigger the animation every time
            onChange={(inView, entry) => {
              if (inView) {
                controls.start({ x: 0, opacity: 1 });
              } else {
                controls.start({ x: -100, opacity: 0 });
              }
            }}
          >
            <Stack spacing={{ base: "8", md: "12" }}>
              <MotionStack animate={controls} initial={{ x: -100, opacity: 0 }} transition={{ duration: 1 }} spacing="4">
                <Badge variant="pill" colorScheme="blue" alignSelf="start" size={{ base: "md", md: "lg" }}>
                  New services available
                </Badge>
                <Stack spacing={{ base: "4", md: "6" }} maxW={{ md: "xl", lg: "md", xl: "xl" }}>
                  <Heading size={{ base: "md", md: "xl" }}>Code Fast, Grow Faster</Heading>
                  <Text fontSize={{ base: "lg", md: "xl" }} color="fg.muted">
                    Expertise at the Speed of Innovation. At CODEHARDY, we deliver top-tier tech solutions – websites, apps, databases – faster and more cost-effectively than the big players. Redefining digital consultancy for the agile age.
                  </Text>
                </Stack>
              </MotionStack>
              <Stack direction={{ base: "column", md: "row" }} spacing="3">
                <Button
                  bg="brand.100"
                  color="white"
                  onClick={() => {
                    // scroll to the id contact-form
                    const element = document.getElementById("contact-form");
                    element?.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Get in contact
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    // scroll to the id contact-form
                    const element = document.getElementById("about");
                    element?.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Learn more
                </Button>
              </Stack>
            </Stack>
          </InView>
          <Box
            pos={{ lg: "absolute" }}
            right="0"
            bottom="0"
            w={{ base: "full", lg: "50%" }}
            height={{ base: "96", lg: "full" }}
            sx={{
              clipPath: { lg: "polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)" },
            }}
          >
            <Img boxSize="full" objectFit="cover" src="https://images.unsplash.com/photo-1614741118887-7a4ee193a5fa?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Lady at work" />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
