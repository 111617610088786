export const jobListings = [
  {
    uid: "job001",
    title: "Website Development",
    department: "Engineering",
    description: "Step into the spotlight with bespoke website solutions. Our websites are crafted for performance, responsiveness, and aesthetic appeal, tailored to your brand's unique identity. From informational sites to complex e-commerce platforms, we build web experiences that engage and convert.",
  },
  {
    uid: "job002",
    title: "Native App Development",
    department: "Engineering",
    description: "Go mobile and reach your audience wherever they are with our native app development services. We create intuitive, efficient, and dynamic mobile applications for both iOS and Android platforms, delivering seamless user experiences that elevate your brand and keep customers coming back.",
  },
  {
    uid: "job003",
    title: "Hosting & Deployments",
    department: "Operations",
    description: "Reliability is key to staying online and accessible. We offer premium hosting services that ensure your website and apps are always up and running. With our continuous deployment strategies, your digital offerings stay updated with the latest features and security measures.",
  },
  {
    uid: "job004",
    title: "Database Management & Setups",
    department: "Architecture",
    description: "Your data is the lifeblood of your business. Our database management and setup services ensure your data is structured, secure, and scalable. From database design to optimization and maintenance, we handle it all, so you can focus on what you do best.",
  },
  {
    uid: "job005",
    title: "Game Development",
    department: "Engineering",
    description: "Engage your audience with interactive entertainment. At CODEHARDY, we bring gamification to life with simple yet captivating games. Whether it's a spin-to-win feature for your marketing campaign or a mini-game for your app, we have the creativity and technical expertise to make it both enjoyable and memorable.",
  },
  {
    uid: "job005",
    title: "Tech Consultancy Services",
    department: "Consultancy",
    description: "Whether you're grappling with deployment decisions, hosting options, or strategic tech implementations, our seasoned consultants are here to guide you. We offer expert advice and hands-on support to ensure your tech journey is smooth, efficient, and tailored to meet your business objectives. Let us help you overcome tech hurdles so you can focus on growing your business.",
  },
];

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export type JobListing = ElementType<typeof jobListings>;
